import {
  faBug,
  faHotel,
  faList,
  faPlane,
  faPlaneAlt,
  faPlaneArrival,
  faPlaneDeparture,
  faPowerOff,
  faTasksAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgentApiURL } from "Constants";
import { useUserInformation } from "hooks/various";
import * as moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { State } from "rootExports/rootReducer";
import { TidModal } from "./TransactionModal";

import { faFantasyFlightGames } from "@fortawesome/free-brands-svg-icons";
import { booking_Redirect_Reducer } from "rootExports/BookingRedirectReducer";

export const AppSideBar: React.FC<{ settings: any }> = (props: any) => {
  var setting = props.settings;
  const [showHotelTab, setShowHotelTab] = useState<boolean>(false);
  const sessionInfo = useSelector((state: State) => state.Session);
  const transactions = useSelector(
    (state: State) => state.Session.Transactions
  );
  const showBookingManagerTab = useSelector(
    (state: State) => state.Session.FrontendSettings.showBookingManagerTab
  );
  const showFareQueryTab = useSelector(
    (state: State) => state.Session.FrontendSettings.showFareQueryTab
  );
  const startPage = useSelector(
    (state: State) => state.Session.FrontendSettings.startPage
  );

  const isAdmin = useSelector((state: State) => state.Session.isAdmin);

  var otherSettings: string = useSelector((state: State) => {
    if (
      state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
      state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
    ) {
      return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None';
    }
    return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
  });
  let otherSetting = otherSettings.split("\n");
  let showHotel = otherSetting[6];

  useEffect(() => {
    if (showHotel == "showHotelTab=All") {
      setShowHotelTab(true);
    } else if (showHotel == "showHotelTab=Admin" && isAdmin) {
      setShowHotelTab(true);
    } else if (showHotel == "showHotelTab=None") {
      setShowHotelTab(false);
    }
  }, [showHotel]);

  const location = useLocation();
  const userInfo = useUserInformation();
  const [transactionModalOpen, setTransactionModalOpen] = useState(false);
  // const DeployInfo = sessionInfo.DeployInfo;
  function OnLogout() {
    // dispatch(Session_Reducer_Reset());
    window.location.href = `/weblogin/${userInfo.WebName}`;
  }
  const dispatch = useDispatch();
  return (
    <div className="w-100 d-flex flex-md-column flex-row flex-grow-1 pt-2 navbar-dark">
      {/* <a href="/" className="d-flex align-items-center pb-sm-3 mb-md-0 me-md-auto text-white text-decoration-none">
            <span className="fs-5">B<span className="d-none d-sm-inline">rand</span></span>
        </a>
         */}
      <img
        src={
          sessionInfo.isLoggedIn && sessionInfo.FrontendSettings.logoID !== ""
            ? `${AgentApiURL}/publicservices/images/logo/${sessionInfo.FrontendSettings.logoID}`
            : "images/logo.png"
        }
        style={{ maxWidth: 180 }}
        className="img-fluid mb-2 companyLogo"
        alt="company logo"
      ></img>

      <ul className="sidebar nav nav-pills flex-md-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-0 justify-content-center">
        {showFareQueryTab && (
          <li className="nav-item text-nowrap text-start">
            <Link
              to={`/auth/availfares`}
              className={`nav-link  ${
                location.pathname.includes("availfares") ? "active" : ""
              }`}
            >
              <FontAwesomeIcon icon={faPlaneDeparture} />{" "}
              <span className="ms-1 d-none d-sm-inline">Flights</span>
            </Link>
          </li>
        )}

        {showHotelTab && (
          <li className="nav-item text-nowrap" onClick={()=>{dispatch(booking_Redirect_Reducer({bookingRedirect:false,checkActiveBtnClicked:'',bookingSegmentsData:[]}))}}>
            <Link
              to={`/auth/hotels`}
              className={`nav-link  ${
                location.pathname.includes("hotels") ? "active" : ""
              }`}
            >
              <FontAwesomeIcon icon={faHotel} />{" "}
              <span className="ms-1 d-none d-sm-inline">Hotels</span>
            </Link>
          </li>
        )}

        {showBookingManagerTab && (
          <li className="nav-item text-nowrap" onClick={()=>{dispatch(booking_Redirect_Reducer({bookingRedirect:false,checkActiveBtnClicked:'',bookingSegmentsData:[]}))}}>
            <Link
              to={`/auth/bookingmanager`}
              className={`nav-link text-white ${
                location.pathname.includes("bookingmanager") ||
                location.pathname.includes("bookingdetails")
                  ? "active"
                  : ""
              }`}
            >
              <FontAwesomeIcon icon={faTasksAlt} />{" "}
              <span className="ms-1 d-none d-sm-inline">Bookings</span>
            </Link>
          </li>
        )}

        <li className="nav-item text-nowrap mt-md-5 clickme">
          <a
            onClick={() => {
              setTransactionModalOpen(!transactionModalOpen);
            }}
            className={
              transactions.length ? `nav-link colorRed` : `nav-link text-light`
            }
          >
            <FontAwesomeIcon icon={faBug} />{" "}
            <span className="ms-1 d-none d-sm-inline">Report</span>
          </a>
        </li>

        <li className="nav-item text-nowrap">
          <a className={`nav-link text-white clickme`} onClick={OnLogout}>
            <FontAwesomeIcon icon={faPowerOff} />{" "}
            <span className="ms-1 d-none d-sm-inline">Logout</span>
          </a>
        </li>
      </ul>
      <TidModal
        open={transactionModalOpen}
        toggle={() => {
          setTransactionModalOpen(!transactionModalOpen);
        }}
      />
    </div>
  );
};

{
  /* <li className="nav-item">
                <div className="content-divider"></div>
            </li> */
}

{
  /* <Dropdown className="mt-md-5" nav={true} isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
                <DropdownToggle caret className="nav-link" >
                    <FontAwesomeIcon icon={faInfoCircle} /> <span className="ms-1 d-none d-sm-inline">About</span>
                </DropdownToggle>
                <DropdownMenu className="">            
                    <DropdownItem onClick={() => { setInfoModalOpen(!infoModalOpen) }}>
                        <FontAwesomeIcon icon={faInfoCircle} /> Version details
                    </DropdownItem>
                    <DropdownItem onClick={() => { setTransactionModalOpen(!transactionModalOpen) }}>
                        <FontAwesomeIcon icon={faBug} /> Report issue
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown> */
}

{
  /* <li className="">
            </li> */
}

{
  /* <li className="nav-item text-nowrap mt-md-5">
                <a className={`nav-link clickme`} onClick={() => { setInfoModalOpen(!infoModalOpen) }}>
                    <FontAwesomeIcon icon={faInfoCircle} /> <span className="ms-1 d-none d-sm-inline">{DeployInfo?.Version}</span>
                </a>
            </li>
            <li className="nav-item text-nowrap">
                <a style={{ color: "red" }} className={`nav-link clickme`} onClick={() => { setTransactionModalOpen(!transactionModalOpen) }}>
                    <FontAwesomeIcon icon={faBug} /> <span className="ms-1 d-none d-sm-inline">Report</span>
                </a>
            </li> */
}

{
  /* <div className="content-divider"></div>
        <ul className="nav nav-pills flex-md-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1  mb-0 justify-content-center align-items-center align-items-sm-start text-white">
            <li className="nav-item">
                <a className="nav-link text-white">
                    <FontAwesomeIcon icon={faUser} /> <span className="ms-1 d-none d-sm-inline">{userInfo.UserName}</span>
                </a>
            </li>
            <li className="nav-item d-sm-none d-md-block">
                <a className="nav-link text-white">
                    <FontAwesomeIcon icon={faGlobe} /> <span className="ms-1 d-none d-sm-inline">{userInfo.WebName}</span>
                </a>
            </li>
            <li className="nav-item">
                <a className="nav-link text-white">
                    <FontAwesomeIcon icon={faClock} /> <b><CountDown.default onComplete={OnLogout} daysInHours={true} date={expires.toDate()}></CountDown.default></b>

                </a>
            </li>


        </ul> */
}
