import { faAngleDoubleLeft, faRedo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BookingDetails from "components/BookingDetails/component/BookingDetails";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import { useSessionHandler } from "hooks/useSessionHandler";
import { useUserInformation } from "hooks/various";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { State } from "rootExports/rootReducer";
import { Session_Reducer_PushTransaction } from "rootExports/SessionReducer";
import { BookingDetailsApiResponse } from "WebApiClient";

const BookingdetailsPage: React.FC<{}> = (props) => {
  const DEBUG = true;
  const showBookingManagerLink = useSelector(
    (state: State) => state.Session.FrontendSettings.showBookingManagerTab
  );

  const navigate = useNavigate();
  const { cartid, bookingid, filekey } = useParams();
  const client = useBookingClient();
  const dispatch = useDispatch();
  const sessionHandler = useSessionHandler();
  const userInfo = useUserInformation();

  const [response, setResponse] = useState<
    BookingDetailsApiResponse | undefined
  >(undefined);
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const result = response?.result;

  useEffect(() => {
    document.title = `Booking details`;
    if (DEBUG) {
      console.log(`Available Fares Page initialized.`);
    }
    Refresh();
    sessionHandler.RefreshSession();
  }, []);

  function Refresh() {
    setResponse(undefined);
    window.scrollTo(0, 0);
    if (cartid && bookingid) {
      GetBookingDetailsByID(cartid, bookingid);
    } else if (filekey) {
      GetBookingDetailsByFileKey(filekey);
    } else {
      setError(true);
    }
  }

  function GetBookingDetailsByFileKey(filekey: string) {
    setFetching(true);
    client
      .getBookingDetailsByFileKey(filekey)
      .then((response) => {
        dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
        if (response.result) {
          setError(false);
          setResponse(response);
        } else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setFetching(false);
      });
  }

  function GetBookingDetailsByID(
    cartID: string,
    bookingID: string,
    emdSelection?: any
  ) {
    setFetching(true);
    client
      .getBookingDetails({
        bookingId: bookingID,
        cartId: cartID,
        emdSelection: emdSelection,
      })
      .then((response) => {
        dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
        setResponse(response);
        if (response.result) {
          setError(false);
        } else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setFetching(false);
      });
  }

  return (
    <div className="" id="">
      <div className="row">
        <div className="col-12 mb-2">{fetching && <WaitingCard />}</div>
        {error && (
          <div className="col-12 mb-2">
            <ErrorDisplay data={response?.responseMetaData} />
          </div>
        )}
        <div className="col-12 mb-2">
          {result && (
            <Fragment>
              <div className="row mb-2">
                <div className="col-12">
                  <div className="card text-center">
                    <div className="row">
                      <div className="col-auto">
                        {showBookingManagerLink && (
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              navigate(
                                `/auth/bookingmanager`
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faAngleDoubleLeft} /> Booking
                            Manager
                          </button>
                        )}
                        <button className="btn btn-cancel" onClick={Refresh}>
                          <FontAwesomeIcon icon={faRedo} /> Refresh
                        </button>
                      </div>
                      <div className="col">
                        {result ? (
                          <span className="align-content-center">
                            Booking details for {" "}
                            <b className="font-weight-bold">
                              {result.latestVersion.generalInfo.filekey}
                            </b>
                          </span>
                        ) : (
                          <span className="text-danger">Booking not found</span>
                        )}
                      </div>
                    </div>
                    {/* <span className="float-end">
            <button className="btn btn-primary btn-sm"><FontAwesomeIcon icon={faShoppingCart}/> Shopping Cart</button>

            </span> */}
                  </div>
                </div>
              </div>
              <BookingDetails Booking={result} ToggleElementsModal={()=>{}}/>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingdetailsPage;
