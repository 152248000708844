import {
  faClock,
  faInfoCircle,
  faSync,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PriceDisplay } from "components/Shared/PriceDisplay";
import { IS_DEBUG } from "Constants";
import { useLocalizedPrice } from "hooks/useLocalization";
import { useBootstrap5MediaQuery } from "hooks/useMediaQuery";
import { useSessionHandler } from "hooks/useSessionHandler";
import { useUserInformation } from "hooks/various";
import * as Moment from "moment";
import { Fragment, useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useDispatch, useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import {
  AgentBudgetInfo,
  AuthorizedServicesClient,
  BookingManagerFilterOptionsResponse,
} from "WebApiClient";
import { AppInfoModal } from "./AppInfoModal";
import {
  useAuthorizedServicesClient,
  useLoginClient,
} from "hooks/useHttpClient";
import {
  Session_Reducer_HideGeneralWaitScreen,
  Session_Reducer_SetFrontendSettings,
  Session_Reducer_SetToken,
  Session_Reducer_ShowGeneralWaitScreen,
  Session_Reducer_isAdmin,
} from "rootExports/SessionReducer";
import jwtDecode from "jwt-decode";

export const AppTopBar: React.FC<{ settings: any }> = (props) => {
  var setting = props.settings;
  const userInfo = useUserInformation();
  const expires = Moment.unix(userInfo.ValidUntil);
  const session = useSelector((state: State) => state.Session);
  const frontendSettings = session.FrontendSettings;
  const deployInfo = session.DeployInfo;
  const mediaQuery = useBootstrap5MediaQuery();
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const x = useSessionHandler();

  function OnLogout() {
    // dispatch(Session_Reducer_Reset());
    window.location.href = `/weblogin/${userInfo.WebName}`;
  }

  const [selectedOption, setSelectedOption] = useState<string>(); // Initial selected option
  const [weboptions, setwebOptions] =
    useState<BookingManagerFilterOptionsResponse>({
      branchGroups: [],
      branches: [],
      users: [],
      membershipMembers: []
    });

  const filter: any = {
    branch: userInfo.BranchName,
    branchGroup: userInfo.BranchgroupName,
  };

  const authServicesClient: AuthorizedServicesClient =
    useAuthorizedServicesClient();
  const [webSelectionMode, setWebSelectionMode] = useState(true);
  const [weboptionsOptionsError, setweboptionsOptionsError] = useState(false);
  const [weboptionsOptionsLoading, setweboptionsOptionsLoading] =
    useState(true);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const loginClient = useLoginClient();
  const jwtToken = useSelector((state: State) => state.Session.JwtToken);

  useEffect(() => {
    if (webSelectionMode) {
      setweboptionsOptionsLoading(true);
      authServicesClient.getBookingManagerFilterOptions({
        branch: filter.branch,
        branchgroup: filter.branchGroup,
      })
        .then((res) => {
          setwebOptions(res);
          setweboptionsOptionsError(false);
        })
        .catch(() => {
          setweboptionsOptionsError(true);
        })
        .finally(() => {
          setweboptionsOptionsLoading(false);
        });
    }
  }, [filter.branch, filter.branchGroup, webSelectionMode]);

  useEffect(() => {
    setSelectedOption(userInfo.BranchName);
  }, []);

  async function DeepLogin(Branch: string) {
    try {
      if (!userInfo || !userInfo.WebID || !userInfo.Deeplink || !Branch) {
        throw new Error("Invalid user information or missing Branch.");
      }

      const stringQuery = {
        webid: userInfo.WebID,
        deeplinkid: userInfo.Deeplink,
      };

      let queryParams: URLSearchParams | undefined = undefined;
      let universalTempDataID: string | null = null;

      if (stringQuery && Object.keys(stringQuery).length > 0) {
        queryParams = new URLSearchParams(stringQuery);
        universalTempDataID = queryParams.get("universaltempdata");
      }
      dispatch(Session_Reducer_ShowGeneralWaitScreen());
      const loginResult = await loginClient.loginByDeeplinkWithBranch(
        userInfo.WebID,
        userInfo.Deeplink,
        Branch,
        universalTempDataID
      );

      if (loginResult.success) {
        setSelectedOption(Branch);
        if (loginResult.isAdmin) {
          dispatch(Session_Reducer_isAdmin(loginResult.isAdmin));
        }
        if (loginResult.token && loginResult.agentFrontendSettings) {
          const decodedToken: any = jwtDecode(loginResult.token);
          const webName: string = decodedToken.WebName;
          const backendAppversion: string = decodedToken.appversion;
          const deeplink: string = decodedToken.deeplink;
          const webID: string = decodedToken.WebId;
          dispatch(Session_Reducer_SetToken(loginResult.token));
          dispatch(
            Session_Reducer_SetFrontendSettings(
              loginResult.agentFrontendSettings
            )
          );
          const baseurl = `/auth/${Branch}`;
          let resultUrl = "auth/availfares";

          const deeplinkStartpage = queryParams?.get("startpage");

          if (deeplinkStartpage) {
            if (deeplinkStartpage === "bookingmanager") {
              resultUrl = `/bookingmanager`;
            } else if (deeplinkStartpage === "bookingdetails") {
              const filekey = queryParams?.get("filekey");
              if (filekey) {
                resultUrl = `/bookingdetails/${filekey}`;
              }
            } else if (
              deeplinkStartpage === "availfares" ||
              deeplinkStartpage === "faresearch"
            ) {
              resultUrl = `/availfares/${stringQuery}`;
            }

            // Navigate to the appropriate URL
            // navigate(baseurl + resultUrl);
          } else {
            // Navigate to a default URL
            // navigate(baseurl + resultUrl);
          }
        } else {
          setErrorMessage("An unexpected error has occurred.");
        }
      } else {
        setErrorMessage("Login failed. Please check your credentials.");
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      dispatch(Session_Reducer_HideGeneralWaitScreen());
    }
  }

  var otherSettings: string = useSelector((state: State) => {
    if (
      state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
      state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
    ) {
      return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None';
    }
    return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
  });

  const [isBranchChange, setisBranchChange] = useState<boolean>(false);

  let settingArr = otherSettings.split("\n");

  const fieldContainer =
    settingArr[7] != undefined ? settingArr[7].split("#") : [];

  useEffect(() => {
    try {
      // const isBranch = JSON.parse( settingArr[7] !== undefined ? settingArr[7] : "");
      const isBranch = fieldContainer.includes("BRANCHCHANGE");
      setisBranchChange(isBranch);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      setisBranchChange(false); // Handle parsing error
    }
  }, [fieldContainer]);

  return (
    <div
      style={{
        height: "20px",
        fontSize: "15px",
        background: setting.bgColor,
        color: `${setting.textColor}`,
        opacity: 1,
      }}
      className="col-12"
    >
      <div className="row">
        <div className="col-auto">
          <p>
            {IS_DEBUG && (
              <span
                className="badge rounded-pill"
                onClick={() => {
                  x.RefreshSession();
                }}
                style={{ color: "red" }}
              >
                {mediaQuery}
              </span>
            )}
            <span className="badge rounded-pill">
              U: <b>{userInfo.UserName}</b>
            </span>
            <span className="d-none d-sm-inline">
              <span className="badge rounded-pill">
                W: <b>{userInfo.WebName}</b>
              </span>
              <span className="badge rounded-pill">
                BG: <b>{userInfo.BranchgroupName}</b>
              </span>
              {isBranchChange ? (
                <span key={`aserruiibn`} className="badge rounded-pill">
                  B:&nbsp;
                  <select
                    value={selectedOption}
                    onChange={(e) => {
                      // LoginWithBranch(e.target.value);
                      DeepLogin(e.target.value);
                    }}
                  >
                    {weboptions.branches
                      .slice()
                      .sort((a: any, b: any) => a.label.localeCompare(b.label))
                      .map((branch) => {
                        return (
                          <option
                            key={branch.label}
                            className="fontChang"
                            value={branch.label}
                          >
                            <strong>{branch.label}</strong>
                          </option>
                        );
                      })}
                  </select>
                </span>
              ) : (
                <span className="badge rounded-pill">
                  B: <b>{userInfo.BranchName}</b>
                </span>
              )}
            </span>
            <span className="badge rounded-pill">
              <FontAwesomeIcon icon={faClock} />{" "}
              <b>
                <Countdown
                  onComplete={OnLogout}
                  daysInHours={true}
                  date={expires.toDate()}
                ></Countdown>
              </b>
            </span>
            <span
              className="badge clickme rounded-pill"
              onClick={() => {
                setInfoModalOpen(!infoModalOpen);
              }}
            >
              <FontAwesomeIcon icon={faInfoCircle} /> {deployInfo?.Version}
            </span>
          </p>
        </div>
        {frontendSettings.budgetInfo && (
          <div className="col">
            <div className="mb-0">
              <BudgetIndicator
                data={frontendSettings.budgetInfo}
                currency={frontendSettings.currency}
              />
            </div>
          </div>
        )}
      </div>
      <AppInfoModal
        isOpen={infoModalOpen}
        Toggle={() => {
          setInfoModalOpen(!infoModalOpen);
        }}
      />
    </div>
  );
};

export const BudgetIndicator: React.FC<{
  data: AgentBudgetInfo;
  currency: string;
}> = ({ currency, data }) => {
  const { currentBudget, maximumBudget } = data;
  const remainingPercentage = Math.round((100 * currentBudget) / maximumBudget);
  const sessionHook = useSessionHandler();
  function GetColor() {
    let result = "success";
    if (remainingPercentage < 50) {
      result = "warning";
    }
    if (remainingPercentage < 10) {
      result = "danger";
    }
    return result;
  }

  const localizedCurrentBudget = useLocalizedPrice(
    currentBudget,
    currency,
    undefined
  );
  const localizedTotalBudget = useLocalizedPrice(
    maximumBudget,
    currency,
    undefined
  );

  const detailLabel = `${localizedCurrentBudget} / ${localizedTotalBudget} remaining (${remainingPercentage}%)`;

  return (
    <Fragment>
      <div
        className="float-end"
        onClick={() => {
          sessionHook.RefreshSession();
        }}
        title={detailLabel}
      >
        <div className=""></div>
        <div className="">
          <span style={{ whiteSpace: "nowrap", fontSize: "11.5px" }}>
            <FontAwesomeIcon icon={faSync} />{" "}
            <PriceDisplay price={currentBudget} currency={currency} />{" "}
            <span className="d-none d-md-inline">remaining</span>
          </span>
        </div>

        {/* <div className='col'>
                <div className="">
                    <Progress color={GetColor()} striped value={currentBudget} max={maximumBudget}><span style={{ minWidth: "200px" }}></span>{remainingPercentage}%</Progress>

                </div>
            </div> */}
      </div>
    </Fragment>
  );
};
