import { faShoppingCart } from "@fortawesome/pro-light-svg-icons";
import {
  faChair,
  faMailBulk,
  faMailForward,
  faMailReply,
  faMailbox,
  faMailboxFlagUp,
  faSearch,
  faSync,
  faTasksAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import { useUserInformation } from "hooks/various";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BaseApiResponse,
  BookingItemModel,
  SmartBookResponseDataModel,
} from "WebApiClient";
import SmartBookResponseCard from "./SmartBookResponseCard";
import { useDispatch, useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import ConfirmDetailSendModal from "components/BookingManager/component/ConfirmDetailsendModal";
import BookingMangerSeatmapModal from "components/BookingManager/component/SeatmapModal";
import { useBookingClient } from "hooks/useHttpClient";
import { Session_Reducer_PushTransaction } from "rootExports/SessionReducer";

const FareBookingConfirmation: React.FC<{
  Result: SmartBookResponseDataModel;
  OnNewQuery: () => void;
}> = ({ Result, OnNewQuery }) => {
  const { bookings, shoppingCartID, usedBookingRuleID, totalPrice } = Result;
  const [isSendEmail, setisSendEmail] = useState<boolean>(false);
  const [isSetMap, setSeatMap] = useState<boolean>(false);
  var otherSettings: string = useSelector((state: State) => {
    if (
      state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
      state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
    ) {
      return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None';
    }
    return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
  });
  let settingArr = otherSettings.split("\n");
  const fieldContainer =
    settingArr[7] != undefined ? settingArr[7].split("#") : [];

  useEffect(() => {
    try {
      const isSendEmail = fieldContainer.includes("SENDAUTOCONFIRMATION");
      const isSeatmap = fieldContainer.includes("SEATBOOKCONFIRMATION");
      setSeatMap(isSeatmap);
      setisSendEmail(isSendEmail);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      setisSendEmail(false); // Handle parsing error
    }
  }, [fieldContainer]);

  // const [showEnhancedInformation, setShowEnhancedInformation] = useState(false);
  const labelCol = "col-xs-6 col-md-4 col-lg-2 mb-1";
  const valueCol = "col-auto mb-2";
  const navigate = useNavigate();
  const userInfo = useUserInformation();
  const [
    elementsConfirmEmailSendModalOpen,
    setElementsConfirmEmailSendModalOpen,
  ] = useState(true);
  const [seatmapModalOpen, setSeatmapModalOpen] = useState(false);
  function ToggleConfirmEmailSendModal() {
    setElementsConfirmEmailSendModalOpen(!elementsConfirmEmailSendModalOpen);
  }
  function ToggleSeatmapModal() {
    setSeatmapModalOpen(!seatmapModalOpen);
  }
  const bookingId: any = bookings[0].bookingResponse?.bookingItemID;
  const bookClient = useBookingClient();
  const dispatch = useDispatch();

  const booking: any = {
    cartId: shoppingCartID,
    bookingId: bookingId,
    emailIds: null,
    description: null,
    templateName: null,
    passengerSelection: null,
    segmentSelection: null,
    emdSelection: null,
  };

  const [syncErrorOccured, setSyncErrorOccured] = useState(false);
  const [rowFetching, setRowIsFetching] = useState(false);
  const [syncResponse, setSyncResponse] = useState<BookingItemModel>();

  const OnSync = () => {
    console.log("Before sync");
    setRowIsFetching(true);
    bookClient.syncBooking(booking)
      .then((response) => {
        console.log("Sync response:", response);
        dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
        if (response.responseMetaData.errorOccured) {
          setSyncErrorOccured(true);
        } else {
          setSyncErrorOccured(false);
          console.log("Updated booking:", response.updatedBooking);
          setSyncResponse(response.updatedBooking); // Make sure response.updatedBooking is not undefined
        }
      })
      .catch((error) => {
        console.error("Error occurred during sync:", error);
        setSyncErrorOccured(true);
      })
      .finally(() => {
        console.log("After sync");
        setRowIsFetching(false);
      });
  };

  useEffect(() => {
    OnSync();
  }, [bookingId, shoppingCartID]);

  return (
    <div className="card">
      <div className="card-header card-header-primary">
        <FontAwesomeIcon icon={faShoppingCart} /> Shopping cart confirmation
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12 mb-2">
            <div className="d-grid">
              <div className="btn-group">
                <button
                  className="btn btn-primary me-2"
                  onClick={() => {
                    navigate(
                      `/auth/bookingmanager`
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faTasksAlt} /> Booking manager
                </button>
                {isSetMap && (
                  <button
                    className="btn btn-primary me-2"
                    onClick={ToggleSeatmapModal}
                  >
                    <FontAwesomeIcon icon={faChair} /> Seatmap
                  </button>
                )}

                <button className="btn btn-primary" onClick={OnNewQuery}>
                  <FontAwesomeIcon icon={faSearch} /> New query
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 mb-2">
            <div className="card">
              <div className="card-header card-header-primary">
                General Shopping cart information
              </div>
              <div className="card-body card-body-primary">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className={labelCol}>
                        <p className="font-weight-bold">Total price</p>
                      </div>
                      <div className={valueCol}>
                        <SharedPriceDisplay price={totalPrice} />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className={labelCol}>
                        <p className="font-weight-bold">Amount of bookings</p>
                      </div>
                      <div className={valueCol}>
                        {bookings.length.toString()}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row">
                      <div className={labelCol}>
                        <p className="font-weight-bold">Cart ID</p>
                      </div>
                      <div className={valueCol}>{shoppingCartID}</div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className={labelCol}>
                        <p className="font-weight-bold">Used booking rule ID</p>
                      </div>
                      <div className={valueCol}>{usedBookingRuleID}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {bookings[0].bookingResponse !== null && isSendEmail && (
            <div className="col-12 mb-2">
              <div className="card">
                <div className="card-header card-header-primary">
                  <FontAwesomeIcon icon={faMailBulk} /> Send Email
                </div>
                <div className="card-body card-body-primary">
                  <ConfirmDetailSendModal
                    Booking={Result}
                    Toggle={ToggleConfirmEmailSendModal}
                    IsOpen={elementsConfirmEmailSendModalOpen}
                    IsPopUp={true}
                  />
                </div>
              </div>
            </div>
          )}
          {bookings.map((booking, index) => (
            <div className="col-12 mb-2" key={"booking" + index}>
              <SmartBookResponseCard index={index} Result={booking} />
            </div>
          ))}
          {syncResponse != undefined && (
            <BookingMangerSeatmapModal
              Booking={syncResponse}
              Toggle={ToggleSeatmapModal}
              IsOpen={seatmapModalOpen}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FareBookingConfirmation;
